.contentLogin {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(to right, #FF00E0, #FFA3C2, #FFA3C2, #FFEE88, #FFEE88, #FFFF2B);
  overflow: auto;
}

.buttonApp {
  margin-left: 10px;
  margin-right: 10px;
}
